<template>
  <q-page>
    <q-dialog
      v-model="deviationDisruptionFormModal.state.show"
      :persistent="false"
    >
      <DeviationDisruptionFormModal
        show-event-input
        v-if="deviationDisruptionFormModal.state.show"
      />
    </q-dialog>
    <q-dialog v-model="deviationCrewDeleteModal.state.show" :persistent="false">
      <DeviationDeleteModal v-if="deviationCrewDeleteModal.state.show" />
    </q-dialog>
    <DeviationDisruptionTable
      :addColumns="addColumns"
      :loading="loading.getAll"
      height="calc(100vh - 50px - 68px)"
      :deviation-disruptions="data"
    >
      <template v-slot:top-left-buttons>
        <h6 class="q-ma-none">Störningshantering</h6>
        <q-btn
          v-if="can('deviationManagement.deviationDisruption.create')"
          icon-right="mdi-plus-circle-outline"
          class="q-ml-md"
          label="Lägg till"
          color="primary"
          @click="
            () => deviationDisruptionFormModal.openModal({ mode: 'create' })
          "
        />
      </template>

      <template v-slot:top-right-buttons>
        <DeviationFilterModal deviation-type="deviationDisruption" />
      </template>
    </DeviationDisruptionTable>
  </q-page>
</template>

<script lang="ts">
import { defineComponent, provide, watch } from 'vue'
import DeviationDisruptionTable from '@/components/deviation/deviation-disruption/DeviationDisruptionTable.vue'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationDisruptionFormModal from '@/components/deviation/deviation-disruption/DeviationDisruptionFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { useDeviationDisruption } from '@/composable/useDeviationDisruption'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'DisruptionDeviations',

  components: {
    DeviationDisruptionTable,
    DeviationDeleteModal,
    DeviationDisruptionFormModal,
    DeviationFilterModal,
  },

  setup() {
    const { can } = useProfile()
    const { data, loading, fetchAll, period } = useDeviationDisruption()
    const deviationDisruptionFormModal = useModal()
    const deviationCrewDeleteModal = useModal()
    provide('deviation-disruption-form-modal', deviationDisruptionFormModal)
    provide('deviation-delete-modal', deviationCrewDeleteModal)

    watch(period, fetchAll, { immediate: true })

    return {
      addColumns,
      data,
      loading,
      deviationDisruptionFormModal,
      deviationCrewDeleteModal,
      can,
    }
  },
})
</script>
