
import { defineComponent, provide, watch } from 'vue'
import DeviationDisruptionTable from '@/components/deviation/deviation-disruption/DeviationDisruptionTable.vue'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationDisruptionFormModal from '@/components/deviation/deviation-disruption/DeviationDisruptionFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { useDeviationDisruption } from '@/composable/useDeviationDisruption'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'DisruptionDeviations',

  components: {
    DeviationDisruptionTable,
    DeviationDeleteModal,
    DeviationDisruptionFormModal,
    DeviationFilterModal,
  },

  setup() {
    const { can } = useProfile()
    const { data, loading, fetchAll, period } = useDeviationDisruption()
    const deviationDisruptionFormModal = useModal()
    const deviationCrewDeleteModal = useModal()
    provide('deviation-disruption-form-modal', deviationDisruptionFormModal)
    provide('deviation-delete-modal', deviationCrewDeleteModal)

    watch(period, fetchAll, { immediate: true })

    return {
      addColumns,
      data,
      loading,
      deviationDisruptionFormModal,
      deviationCrewDeleteModal,
      can,
    }
  },
})
